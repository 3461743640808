import Vue from "vue";
import VueRouter from "vue-router";

import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

export const Routes = {
  HOME: "HomeView",
  ABOUT: "AboutView",
  PARTNERS: "PartnersView",
  DISSEMINATION: "Dissemination",
  BLOG_20210226: "Blog_20210226",
  MEETING_IN_OSLO: "MeetingInOslo",
  CONTACT: "ContactView",
  TEAM: "TeamView",
  QUERY: "QueryView",
  QUERIES: "QueriesView",
  QUERIES_EDIT: "QueriesEditView",
  ARTICLE_LIST: "ArticleListView",
  TASK_LIST: "TaskListView",
  PROCESS_LIST: "ProcessListView",
  PROJECTS_LIST: "ProjectsListView",
  PROJECT_DETAILS: "ProjectDetailsView",
  PROJECT_EDIT: "ProjectEditView",
  PROJECT_NEW: "PRojectEditView",
  EXPERT_LABEL_ARTICLES: "ExpertLabelArticles",
  USER_CHOOSE_BOW_BOC_ANNOTATOR: "UserChooseBowBocAnnotator",
  USER_EXPLORE_RESULT: "UserExploreResult",
  POLISH_AI_CONFERENCE: "PolishAIConference",
  IFIP_CONFERENCE: "IFIPConference",
  MEETING_IN_OSLO_2023: "MeetingInOslo_10_2023",
  IEEE_CONFERENCE: "IEEEConference",
  MEETING_IN_WARSAW: "MeetingInWarsaw",
  DOCUMENTATION: "Documentation",
  OPEN_SOURCE_CODE: "OpenSourceCode"
};

const routes = [
  {
    path: "/",
    name: Routes.HOME,
    meta: {
      isAuthenticated: false,
    },
    component: HomeView,
  },
  {
    path: "/about",
    name: Routes.ABOUT,
    meta: {
      isAuthenticated: false,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ProjectView" */ "@/views/AboutView.vue"),
  },
  {
    path: "/partners",
    name: Routes.PARTNERS,
    meta: {
      isAuthenticated: false,
    },
    component: () => import(/* webpackChunkName: "PartnersView" */ "@/views/PartnersView.vue"),
  },
  {
    path: "/dissemination",
    component: () => import(/* webpackChunkName: "DisseminationView" */ "@/views/DisseminationView.vue"),
    children: [
      {
        path: "",
        name: Routes.DISSEMINATION,
        meta: {
          isAuthenticated: false,
        },
        component: () => import(/* webpackChunkName: "Dissemination" */ "@/components/DisseminationInfo.vue"),
      },
      {
        path: "20210226",
        name: Routes.BLOG_20210226,
        meta: {
          isAuthenticated: false,
        },
        component: () => import(/* webpackChunkName: "Blog_20210226" */ "@/views/blog/Blog_20210226.vue"),
      },
      {
        path: "meeting-in-oslo-13-14-06-2022",
        name: Routes.MEETING_IN_OSLO,
        meta: {
          isAuthenticated: false,
        },
        component: () => import(/* webpackChunkName: "Blog_20210226" */ "@/views/blog/MeetingInOslo.vue"),
      },
      {
        path: "polish-ai-conference",
        name: Routes.POLISH_AI_CONFERENCE,
        meta: {
          isAuthenticated: false,
        },
        component: () => import(/* webpackChunkName: "Blog_20210226" */ "@/views/blog/PolishAIConference.vue"),
      },
      {
        path: "ifip-conference",
        name: Routes.IFIP_CONFERENCE,
        meta: {
          isAuthenticated: false,
        },
        component: () => import(/* webpackChunkName: "Blog_20210226" */ "@/views/blog/IFIPConference.vue"),
      },
      {
        path: "ieee-conference",
        name: Routes.IEEE_CONFERENCE,
        meta: {
          isAuthenticated: false,
        },
        component: () => import(/* webpackChunkName: "Blog_20210226" */ "@/views/blog/IEEEConference.vue"),
      },
      {
        path: "meeting-in-oslo-27-10-2023",
        name: Routes.MEETING_IN_OSLO_2023,
        meta: {
          isAuthenticated: false,
        },
        component: () => import(/* webpackChunkName: "Blog_20210226" */ "@/views/blog/MeetingInOlso2023.vue"),
      },
      {
        path: "meeting-in-warsaw",
        name: Routes.MEETING_IN_WARSAW,
        meta: {
          isAuthenticated: false,
        },
        component: () => import(/* webpackChunkName: "Blog_20210226" */ "@/views/blog/MeetingInWarsaw.vue"),
      },
    ],
  },
  {
    path: "/documentation",
    name: Routes.DOCUMENTATION,
    meta: {
      isAuthenticated: false,
    },
    component: () => import(/* webpackChunkName: "ContactView" */ "@/views/DocumentationView.vue"),
  },
  {
    path: "/open-source-code",
    name: Routes.OPEN_SOURCE_CODE,
    meta: {
      isAuthenticated: false,
    },
    component: () => import(/* webpackChunkName: "ContactView" */ "@/views/OpenSourceCodeView.vue"),
  },
  {
    path: "/contact",
    name: Routes.CONTACT,
    meta: {
      isAuthenticated: false,
    },
    component: () => import(/* webpackChunkName: "ContactView" */ "@/views/ContactView.vue"),
  },
  {
    path: "/team",
    name: Routes.TEAM,
    meta: {
      isAuthenticated: false,
    },
    component: () => import(/* webpackChunkName: "TeamView" */ "@/views/TeamView.vue"),
  },
  {
    path: "/queries",
    name: Routes.QUERIES,
    meta: {
      isAuthenticated: true,
    },
    component: () => import(/* webpackChunkName: "QueryView" */ "@/views/QueriesView.vue"),
  },
  {
    path: "/queries/:requestId",
    name: Routes.QUERIES_EDIT,
    meta: {
      isAuthenticated: true,
    },
    component: () => import(/* webpackChunkName: "QueryView" */ "@/views/QueriesView.vue"),
  },
  {
    path: "/articles",
    name: Routes.ARTICLE_LIST,
    meta: {
      isAuthenticated: true,
    },
    component: () => import(/* webpackChunkName: "ArticleListView" */ "@/views/ArticleListView.vue"),
  },
  {
    path: "/tasks",
    name: Routes.TASK_LIST,
    meta: {
      isAuthenticated: true,
    },
    component: () => import(/* webpackChunkName: "TaskListView" */ "@/views/TaskListView.vue"),
  },
  {
    path: "/processes",
    name: Routes.PROCESS_LIST,
    meta: {
      isAuthenticated: true,
    },
    component: () => import(/* webpackChunkName: "ProcessListView" */ "@/views/ProcessListView.vue"),
  },

  // ***** PROJECTS *****
  {
    path: "/projects",
    name: Routes.PROJECTS_LIST,
    meta: {
      isAuthenticated: true,
    },
    component: () => import(/* webpackChunkName: "ProcessListView" */ "@/views/ProjectsListView.vue"),
  },
  {
    path: "/projects/:projectId",
    name: Routes.PROJECT_DETAILS,
    meta: {
      isAuthenticated: true,
    },
    component: () => import(/* webpackChunkName: "ProcessListView" */ "@/views/ProjectDetailsView.vue"),
  },
  {
    path: "/projects/:projectId/edit",
    name: Routes.PROJECT_EDIT,
    meta: {
      isAuthenticated: true,
    },
    component: () => import(/* webpackChunkName: "ProcessListView" */ "@/views/ProjectEditNewView.vue"),
  },
  {
    path: "/new-project",
    name: Routes.PROJECT_NEW,
    meta: {
      isAuthenticated: true,
    },
    component: () => import(/* webpackChunkName: "ProcessListView" */ "@/views/ProjectEditNewView.vue"),
  },

  // ***** CUSTOM TASK FORMS *****
  {
    path: "/expert-label-articles/:processId/:taskId",
    name: "ExpertLabelArticles",
    meta: {
      isAuthenticated: true,
    },
    component: () => import(/* webpackChunkName: "ExpertLabelArticles" */ "@/views/customforms/ExpertLabelArticles.vue"),
  },
  {
    path: "/user-choose-bow-boc-annotator/:processId/:taskId",
    name: "UserChooseBowBocAnnotator",
    meta: {
      isAuthenticated: true,
    },
    component: () =>
      import(/* webpackChunkName: "UserChooseBowBocAnnotator" */ "@/views/customforms/UserChooseBowBocAnnotator.vue"),
  },
  {
    path: "/user-explore-result/:processId/:taskId",
    name: "UserExploreResult",
    meta: {
      isAuthenticated: true,
    },
    component: () => import(/* webpackChunkName: "UserExploreResult" */ "@/views/customforms/UserExploreResult.vue"),
  },
  {
    path: "/choose-snowball-parameters/:processId/:taskId",
    name: "ChooseSnowballParameters",
    meta: {
      isAuthenticated: true,
    },
    component: () => import(/* webpackChunkName: "ChooseSnowballParameters" */ "@/views/customforms/ChooseSnowballParameters.vue"),
  },
  {
    path: "/user-explore-enriched-result/:processId/:taskId",
    name: "UserExploreEnrichedResult",
    meta: {
      isAuthenticated: true,
    },
    component: () => import(/* webpackChunkName: "UserExploreEnrichedResult" */ "@/views/customforms/UserExploreEnrichedResult.vue"),
  },

  // ***** UNAUTHORIZED *****
  {
    path: "/unauthorized",
    name: "Unauthorized",
    meta: {
      isAuthenticated: false,
    },
    component: () => import(/* webpackChunkName: "QueryView" */ "@/views/UnauthorizedView.vue"),
  },
];

const router = new VueRouter({
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.meta.isAuthenticated) {
//     // Get the actual url of the app, it's needed for Keycloak
//     const basePath = window.location.toString()
//     if (!Vue.$keycloak.authenticated) {
//       // The page is protected and the user is not authenticated. Force a login.
//       Vue.$keycloak.login({ redirectUri: basePath.slice(0, -1) + to.path })
//     } else if (Vue.$keycloak.hasResourceRole('default-roles-refsa')) {
//       // The user was authenticated, and has the app role
//       Vue.$keycloak.updateToken(70)
//         .then(() => {
//           next()
//         })
//         .catch(err => {
//           console.error(err)
//         })
//     } else {
//       // The user was authenticated, but did not have the correct role
//       // Redirect to an error page
//       next({ name: 'Unauthorized' })
//     }
//   } else {
//     // This page did not require authentication
//     next()
//   }
// })
router.beforeEach((to, from, next) => {
  if (to.meta.isAuthenticated) {
    // Get the actual url of the app, it's needed for Keycloak
    var getUrl = window.location;
    var baseUrl = getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split("/")[1] + "#/";
    // window.alert(baseUrl.slice(0, -1) + to.path);
    if (!Vue.$keycloak.authenticated) {
      // The page is protected and the user is not authenticated. Force a login.
      Vue.$keycloak.login({ redirectUri: baseUrl.slice(0, -1) + to.path });
    } else {
      // The user was authenticated, and has the app role
      Vue.$keycloak
        .updateToken(70)
        .then(() => {
          next();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  } else {
    // This page did not require authentication
    next();
  }
});

export default router;

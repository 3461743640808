import axios from "axios";
import { defineStore } from "pinia";
import Vue from "vue";

export const useTaskStore = defineStore("taskStore", {
  state: () => ({
    taskCount: 0,
    tasks: [],
    loading: false,
    completing: false,
    page: 0,
  }),
  getters: {
    getTaskCount(state) {
      return state.taskCount;
    },
  },
  actions: {
    async fetchTasks(options = { page: 0, size: 20, query: "", projectId: null, processId: null }) {
      this.page = options.page;

      this.loading = true;
      var params = new URLSearchParams();
      params.append("limit", options.size);
      params.append("offset", options.page);
      if (options.query) {
        params.append("query", options.query);
      }
      if (options.projectId) {
        params.append("projectId", options.projectId);
      }
      if (options.processId) {
        params.append("processId", options.processId);
      }
      var headers = {
        Authorization: `Bearer ${Vue.prototype.$keycloak.token}`,
      };
      var config = {
        params: params,
        headers: headers,
      };
      try {
        const response = await axios.get("/api/process-manager/tasks/", config);
        console.log("fetchTasks -> response:", response);
        this.tasks = response.data.data;
        this.taskCount = response.data.totalCount;
        console.log("fetchTasks -> tasks:", this.tasks);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        console.log("fetchTasks -> tasks:", this.tasks);
      }
    },

    hideTask(taskId) {
      this.tasks = this.tasks.filter((el) => el.taskId !== taskId);
      this.taskCount -= 1;
    },

    async completeTask(taskId) {
      this.completing = true;
      try {
        const response = await axios.post(
          `/api/process-manager/tasks/${taskId}/approve`,
          {
            // numericalRepresentationType: self.numericalRepresentation,
            // annotatorType: self.annotator,
          },
          { headers: { Authorization: `Bearer ${this.$keycloak.token}` } }
        );
        console.log("completeTask -> response", response);
      } catch (error) {
        console.log("completeTask -> error", error);
      } finally {
        this.completing = false;
      }
    },
  },
});

import axios from "axios";
import { defineStore } from "pinia";

import { mapContent, mapTotalElements } from "@/utils/storeUtils";

const API_PROJECTS = "api/projects";

export const projects = defineStore({
  id: "projects",
  state: () => ({
    activeProject: null,
    projectDetalis: null,
    projects: [],
    projectsTotalElements: 0,
    userProjects: [],
  }),
  getters: {
    getActiveProject(state) {
      return state.activeProject;
    },
    getProjects(state) {
      return state.projects;
    },
    getProjectsTE(state) {
      return state.projectsTotalElements;
    },
    getUserProjects(state) {
      return state.userProjects;
    },
    getProjectDetails(state) {
      return state.projectDetalis;
    },
  },
  actions: {
    async fetchUsersProjects() {
      const response = await axios.get(`${API_PROJECTS}/logged`);
      this.userProjects = response.data;
    },
    async fetchProjects({ page, size, query, sort }) {
      const response = await axios.get(`${API_PROJECTS}`, { params: { page, size, query, sort } });
      this.projects = mapContent(response.data);
      this.projectsTotalElements = mapTotalElements(response.data);
    },
    async fetchProjectDetails(id) {
      const response = await axios.get(`${API_PROJECTS}/${id}`);
      this.projectDetalis = response.data;
      return response.data;
    },
    async updateProject(project) {
      const response = await axios.put(`${API_PROJECTS}/${project.id}`, project);
      this.projectDetalis = response.data;
      return response.data;
    },
    async deleteProject(id) {
      await axios.delete(`${API_PROJECTS}/${id}`);
    },
    async createProject(project) {
      const response = await axios.post(`${API_PROJECTS}`, {
        name: project.name,
        description: project.description,
        members: [],
      });
      this.projectDetalis = response.data;
      return response.data;
    },
    setActiveProject(id) {
      if (!this.userProjects.length) {
        throw new Error("ERROR: load list are empty");
      }
      const project = this.userProjects.find((val) => val.id === Number(id));
      if (!project) {
        throw new Error("ERROR: cannot find project with id: ", id);
      }
      this.activeProject = project;
      localStorage.setItem("lastVisitetProjectId", id);
    },
  },
});

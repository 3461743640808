import Vue from 'vue'
import Keycloak from 'keycloak-js'
import axios from 'axios'

const TOKEN_REFRESH_INTERVAL = 60000

const options = {
    url: 'https://refsa-project.com/auth/',
    realm: 'refsa',
    clientId: 'refsa-web',
}

const _keycloak = Keycloak(options)

const Plugin = {
  install(Vue) {
    Vue.$keycloak = _keycloak
    console.log("token: ",_keycloak.tokenParsed)
  }
}

Plugin.install = Vue => {
  Vue.$keycloak = _keycloak
  Object.defineProperties(Vue.prototype, {
    $keycloak: {
      get() {
        return _keycloak
      }
    }
  })
}

export function setTokenUpdater(keycloak) {
  console.log("token: ",keycloak.tokenParsed)
  if (keycloak.tokenParsed) {
    setInterval(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            console.debug(`Token refreshed${refreshed}`)
          } else {
            // const time = ( keycloak.tokenParsed?.exp || 0) + (keycloak.timeSkew || 0 )
            console.warn(
              `Token not refreshed, valid for ${Math.round(
                (keycloak.tokenParsed.exp ?? 0) + (keycloak.timeSkew ?? 0) - new Date().getTime() / 1000
              )} seconds`
            )
          }
        })
        .catch(() => {
          console.error("Failed to refresh token")
        })
    }, TOKEN_REFRESH_INTERVAL)
  }
}

export function setTockenInterceptor(keycloak) {
    axios.interceptors.request.use(
      (config) => {
        // eslint-disable-next-line no-param-reassign
        if (keycloak.token) {
          config.headers.Authorization = `Bearer ${keycloak.token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
}
Vue.use(Plugin)

export default Plugin

<template>
  <v-container>
    <v-card flat>
      <v-card-title class="text-h4 text--secondary mb-3">
        <v-spacer></v-spacer>
        Welcome to REFSA Project
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-row
          class="text-center"
          justify="center"
        >
          <!-- <v-col class="mb-4" cols="12" sm="12">
            <div class="text-h4 text--secondary mb-3">
              Welcome to REFSA Project
            </div>
          </v-col> -->

          <v-col
            cols="12"
            sm="12"
          >
            <v-img
              class="mx-auto mb-3"
              src="@/assets/REFSA_logo_vertical.png"
              width="300"
              height="300"
              contain
            ></v-img>
          </v-col>

          <v-col
            class=""
            cols="12"
            sm="12"
          >
            <div class="text-subtitle1 text--secondary">
              Machine Learning-based system for the automation of systematic literature reviews in food safety domain
            </div>
          </v-col>
          <v-col
            v-if="projectsStore.getActiveProject"
            cols="12"
            class="py-0"
          >
            <v-row
              no-gutters
              justify="center"
              align="center"
            >
              <v-col cols="auto">
                <v-card-title class="text-h5 text--secondary text-center py-2 pr-1"> Current project: </v-card-title>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  class="text-h5 py-2"
                  plain
                  color="primary"
                  :to="{ name: Routes.PROJECT_DETAILS, params: { projectId: projectsStore.getActiveProject.id } }"
                >
                  {{ projectsStore.getActiveProject.name }}
                  <v-icon></v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-btn
              block
              rounded
              color="primary"
              :to="{ name: Routes.PROJECT_NEW }"
            >
              Create Project
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn
              block
              outlined
              rounded
              color="primary"
              :to="{ name: Routes.PROJECTS_LIST }"
            >
              Browse Projects
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import { Routes } from "@/router";
import { projects } from "@/store/projects";

export default defineComponent({
  name: "HomePage",

  data: () => ({ Routes }),
  computed: {
    ...mapStores(projects),
  },
  mounted() {
    console.log(this.$vuetify.theme);
  },
});
</script>

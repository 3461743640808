import { PiniaVuePlugin, createPinia } from "pinia";
import Vue from "vue";

import App from "@/App.vue";
import "@/plugins/authentication";
import "@/plugins/tecnavueshared";
import "@/plugins/dayjs"
import vuetify from "@/plugins/vuetify";
import router from "@/router";
import VueInputAutowidth from 'vue-input-autowidth'

//import VueCompositionAPI from '@vue/composition-api'

Vue.use(VueInputAutowidth)
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
//Vue.use(VueCompositionAPI)

Vue.config.productionTip = false;

Vue.$keycloak
  .init({
    // onLoad: 'login-required',
    checkLoginIframe: false,
  })
  .then(() => {
    new Vue({
      router,
      vuetify,
      pinia,
      render: (h) => h(App),
    }).$mount("#app");
  });

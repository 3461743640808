<template>
  <v-app v-if="loading.view">
    <loading-view />
  </v-app>
  <v-app v-else>
    <!-- <v-system-bar style="z-index: 1000; background-color: rgba(0, 0, 0, 0)">
      <span>{{ currentBreakpoint }}</span>
    </v-system-bar> -->

    <v-app-bar
      app
      color="white"
      flat
      dense
    >
      <v-img
        v-if="!$vuetify.breakpoint.xsOnly"
        src="@/assets/REFSA_logo_rectangle.png"
        height="50"
        width="50"
        contain
        position="left"
        class="mt-12 ml-10"
        style="opacity: 1"
      ></v-img>

      <v-row
        class="mt-1"
        justify="end"
        align="center"
        v-if="isUserLogged"
        dense
      >
        <v-col cols="auto">
          <v-btn
            v-if="taskStore.taskCount"
            text
            class="error--text"
            :loading="isLoading"
            @click="$router.push({ name: Routes.TASK_LIST })"
          >
            <div class="d-none d-md-inline">You have</div>
            <div class="ml-1 d-inline">{{ taskStore.taskCount }} tasks</div>
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <div class="ml-1 mt-2 text-body-2 d-none d-md-inline">Logged as</div>
          <div class="ml-1 mt-2 d-inline text-body-2">
            {{ $keycloak.tokenParsed.name }}
          </div>
          <v-btn
            text
            fab
            small
            class="mt-n1"
          >
            <v-icon @click="logout"> mdi-logout </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div
        v-else
        class="mt-3"
      >
        <v-btn
          text
          @click="signin()"
          >Sign In</v-btn
        >
      </div>

      <template v-slot:extension>
        <v-tabs
          centered
          class="ml-n9"
          color="grey darken-1"
        >
          <v-tab to="/">
            <v-img
              src="@/assets/REFSA_logo_text.png"
              width="50"
              height="32"
              contain
            ></v-img>
          </v-tab>
          <v-tab
            v-for="link in userLinks"
            :key="link.name"
            :to="{ name: `${link.to}` }"
          >
            {{ link.name }}
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container
        :fluid="$vuetify.breakpoint.mdAndDown"
        class="fill-height"
      >
        <v-row
          align="start"
          class="fill-height"
        >
          <v-col
            v-if="!$vuetify.breakpoint.xsOnly"
            cols="12"
            sm="2"
          >
            <v-sheet
              rounded="lg"
              min-height="300"
            >
              <ProgrammeOperators />
            </v-sheet>
          </v-col>

          <v-col
            cols="12"
            sm="8"
          >
            <v-sheet rounded="lg" class="mb-8">
              <router-view :projectSelectDisable.sync="projectSelectDisable" />
            </v-sheet>
          </v-col>

          <v-col
            cols="12"
            sm="2"
          >
            <v-sheet
              rounded="lg"
              min-height="300"
            >
              <PartnersInfo />
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapStores } from "pinia";
import { defineComponent } from "vue";

import PartnersInfo from "@/components/PartnersInfo.vue";
import ProgrammeOperators from "@/components/ProgrammeOperators.vue";

import { Routes } from "@/router";
import { useTaskStore } from "@/store/TaskStore.js";
import { projects } from "@/store/projects";
import LoadingView from "@/views/LoadingView.vue";

import { setTockenInterceptor, setTokenUpdater } from "./plugins/authentication";

export default defineComponent({
  name: "App",
  components: {
    PartnersInfo,
    ProgrammeOperators,
    LoadingView,
  },

  setup() {
    const taskStore = useTaskStore();
    return { taskStore };
  },

  data: () => ({
    Routes,
    loading: {
      view: true,
    },
    links: [
      { name: "About", to: Routes.ABOUT, loginRequired: false },
      { name: "Partners", to: Routes.PARTNERS, loginRequired: false },
      { name: "Team", to: Routes.TEAM, loginRequired: false },
      { name: "Dissemination", to: Routes.DISSEMINATION, loginRequired: false },
      { name: "Documentation", to: Routes.DOCUMENTATION, loginRequired: false },
      { name: "Open source code", to: Routes.OPEN_SOURCE_CODE, loginRequired: false },
      { name: "SLR Projects", to: Routes.PROJECTS_LIST, loginRequired: true },
      // { name: "SLR Processes", to: Routes.PROCESS_LIST, loginRequired: true },
      // { name: "Contact", to: "contact" },
    ],
    // taskCount: 0,
    isLoading: false,
    projectSelectDisable: false,
  }),

  computed: {
    ...mapStores(projects),
    currentBreakpoint() {
      return "breakpoint: " + (this.$vuetify && this.$vuetify.breakpoint.name);
    },

    userLinks() {
      if (this.$keycloak.tokenParsed) {
        return this.links;
      } else {
        return this.links.filter((el) => el.loginRequired === false);
      }
    },
    isUserLogged() {
      return !!this.$keycloak.tokenParsed;
    },
    activeProject: {
      get() {
        return this.projectsStore.getActiveProject;
      },
      set(val) {
        this.projectsStore.setActiveProject(val);
      },
    },
  },

  async created() {
    console.log("name:", this.$keycloak.tokenParsed ? this.$keycloak.tokenParsed.name : "Logged out");
    if (this.isUserLogged) {
      await this.initAfterLogin();
    }
    console.log("breakpoint", this.$vuetify.breakpoint);
    this.loading.view = false;
  },

  watch: {
    $route() {
      // this.countTasks();
      if (this.isUserLogged) {
        this.loadTask();
      }
    },
    "$route.name"() {
      this.projectSelectDisable = false;
    },
  },

  methods: {
    logout() {
      this.$router.push({ name: Routes.HOME });
      this.$keycloak.logout();
      console.log("logout:", this.$keycloak);
    },

    signin() {
      this.$keycloak.login();
      this.$router.push({ name: Routes.PROJECTS_LIST });
      console.log("login:", this.$keycloak);
    },
    async initAfterLogin() {
      setTokenUpdater(this.$keycloak);
      setTockenInterceptor(this.$keycloak);
      this.loadTask();
      await this.loadProjects();
      if (this.projectsStore.getUserProjects.length) {
        const lastVisitedProjectId = localStorage.getItem("lastVisitetProjectId");
        console.log(lastVisitedProjectId);

        try {
          if (lastVisitedProjectId) {
            this.projectsStore.setActiveProject(lastVisitedProjectId);
          } else {
            this.projectsStore.setActiveProject(this.projectsStore.getUserProjects[0].id);
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    async loadTask() {
      try {
        await this.taskStore.fetchTasks();
      } catch (error) {
        console.error(error);
      }
    },
    async loadProjects() {
      try {
        await this.projectsStore.fetchUsersProjects();
      } catch (error) {
        console.error(error);
      }
    },

    // countTasks() {
    //   this.isLoading = true;
    //   var params = new URLSearchParams();
    //   params.append("limit", 1);
    //   params.append("offset", 0);
    //   var headers = { Authorization: `Bearer ${this.$keycloak.token}` };
    //   var config = {
    //     params: params,
    //     headers: headers,
    //   };
    //   axios
    //     .get("/api/process-manager/tasks/", config)
    //     // https://refsa-project.com/api/process-manager/tasks
    //     .then((res) => {
    //       console.log("fetchTasks -> res:", res);
    //       this.taskCount = res.data.totalCount;
    //       console.log("fetchTasks -> taskCount:", this.taskCount);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     })
    //     .finally(() => {
    //       this.isLoading = false;
    //       console.log("fetchTasks -> taskCount:", this.taskCount);
    //     });
    // },
  },
});
</script>

export function mapContent(data) {
  return "content" in data ? data.content : []
}
export function mapTotalElements(data) {
  return "totalElements" in data ? data.totalElements : 0
}
export function mapSort(sort) {
  let sortString = null
  let sortTable = null
  if (sort.sortBy && sort.sortBy.length) {
    sortTable = sort.sortBy.map((val, i) => `${val},${sort.sortDesc[i] ? "desc" : "asc"}`)
    sortString = sortTable.join("&sort=")
  }
  return sortString
}
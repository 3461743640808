// dayjs configuration
import dayjs from "dayjs";
import "dayjs/locale/en";
//Localizations
import "dayjs/locale/pl";
//Plugins
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
// required for timezone
import utc from "dayjs/plugin/utc";

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

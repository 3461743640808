<template>
  <v-container
    fluid
  >
    <v-row
      class="fill"
      justify="center"
      align="center"
    >
      <v-col cols="auto">
        <v-progress-circular
          :size="80"
          :width="5"
          class="ml-auto mr-auto mt-auto mb-auto"
          color="primary"
          indeterminate
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoadingView",
  data() {
    return {};
  },
});
</script>

<style scoped>
.fill {
  height: 100vh;
  width: 100vw;
}
</style>
